
import React, { useContext, useEffect } from 'react';
import { Button, Space, Form, Alert, Select, Spin, Empty } from 'antd';
import WordTag from '../components/WordTag';

import { LanguageContext } from "../App"

import { locales } from '../locales';
import NounFormCard from '../components/NounFormCard';


const Nouns = () => {
    const url = "https://process-polish-api-webapp.azurewebsites.net"

    const [result, setResult] = React.useState()
    const [resultLoading, setResultLoading] = React.useState(false)
    const language = useContext(LanguageContext)
    const [error, setError] = React.useState()
    const [options, setOptions] = React.useState([])
    const [searchText, setSearchText] = React.useState()

    useEffect(() => {
        if (!searchText) {
            setOptions([])
            return
        }
        if (searchText.length < 2) {
            setOptions([])
            return
        }

        const getData = setTimeout(() => {
            fetch(`${url}/nouns/?` + new URLSearchParams({ query: searchText })).then(res => {
                if (!res.ok) {
                    throw Error("failed to fetch verbs")
                }
                return res.json()
            }).then(data => {
                const options = data.words.filter((s) => s.startsWith(searchText))
                const options_obj = options.map(option => {
                    return { "value": option }
                })
                setOptions(options_obj)
            }).catch(err => {
                setError(err.message)
            })
        }, 1000)
        return () => clearTimeout(getData)
    }, [searchText])


    const onFinish = (values) => {
        setResultLoading(true)
        setResult(null)
        setError(null)
        const noun = values.noun.trim().toLowerCase()
        const body = JSON.stringify({ noun, language })
        fetch(`${url}/noun/`, { method: 'POST', body })
            .then(res => {
                if (!res.ok) {
                    throw Error()
                }
                return res.json()
            }
            )
            .then(data => {
                setResult(data)
            })
            .catch(err => {
                setError(err.message)
            })
            .finally(() => {
                setResultLoading(false)
            })
    }


    useEffect(() => {
        // Clear the results when changing language
        setResult()
        setError()
        setOptions()
    }, [language]);


    return (
        <div style={{ width: "95%", maxWidth: "900px" }}>
            <Form onFinish={onFinish}>
                <Form.Item name="noun" rules={[{ "required": true, "message": locales[language].nounValidation }]} >
                    <Select
                        showSearch
                        options={options}
                        placeholder={locales[language].nounPlaceholder}
                        onSearch={(text) => setSearchText(text)}
                        notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={locales[language].startTypingText} />}
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' size="small">{locales[language].nounButtonLabel}</Button>
                </Form.Item>
            </Form>
            {
                resultLoading && <Spin />
            }
            {
                error && (
                    <Alert className="Alert" message={error} type="error" />
                )
            }
            {
                result && (
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <WordTag word={result.text} pos="NOUN" data={result.data} />
                        {
                            result.forms.map(form => <NounFormCard number_label={form.number_label} cases={form.cases} />)
                        }
                    </Space>)

            }

        </div >
    )
}

export default Nouns
