import React from 'react';
import { Card, Table } from 'antd';

const columns = [
    {
        title: '',
        dataIndex: 'person_label_pl',
        key: 'person_label_pl',
        align: "center",
        width: "10%"
    },
    {
        title: '',
        dataIndex: 'person_label',
        key: 'person_label',
        align: "center",
        width: "10%"
    },
    {
        title: '',
        dataIndex: 'verb',
        key: 'verb',
        align: "center",
        width: "25%"
    },
]

const VerbFormCard = ({ title, data }) => {
    // data should have 2 columns
    return (
        <Card title={title}>
            <Table columns={columns} dataSource={data} pagination={false} showHeader={false} size={'small'} />
        </Card>
    )
}

export default VerbFormCard
