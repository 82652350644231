import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { Typography } from 'antd';
import { locales } from '../locales';
import { LanguageContext } from "../App"


const { Title, Paragraph } = Typography;


const Home = () => {
    const language = useContext(LanguageContext);

    return (
        <Typography>
            <Paragraph>
                {locales[language].homeWelcomeText}
            </Paragraph>
            <Paragraph>
                {locales[language].homeDescriptionText}
            </Paragraph>
            <Title level={2}>
                <Link to="/verbs">{locales[language].menuConjugateItemLabel}</Link>
            </Title>
            <Paragraph>
                {locales[language].homeConjugateDescriptionText}
            </Paragraph>
            <Title level={2}>
                <Link to="/nouns">{locales[language].menuNounsItemLabel}</Link>
            </Title>
            <Paragraph>
                {locales[language].homeNounsDescriptionText}
            </Paragraph>
            <Title level={2}>
                <Link to="/process">{locales[language].menuProcessItemLabel}</Link>
            </Title>
            <Paragraph>
                {locales[language].homeProcessDescriptionText}
            </Paragraph>
        </Typography>
    )
}
export default Home