import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Process from './routes/Process';
import Home from './routes/Home';
import Verbs from './routes/Verbs';
import Nouns from './routes/Nouns';
import About from './routes/About';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children:
      [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "process/",
          element: <Process />,
        },
        {
          path: "verbs/",
          element: <Verbs />,
        },
        {
          path: "nouns/",
          element: <Nouns />,
        },
        {
          path: "about/",
          element: <About />,
        },
      ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
