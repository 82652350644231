import React from 'react';
import { Typography, Collapse } from 'antd';


const { Title, Paragraph } = Typography;

const morf = (<Typography>
    <Title level={2}>Morfeusz 2</Title>
    <Paragraph>
        Conjugation functionality is provided by the Morfeusz2 library.
    </Paragraph>
    <Title level={3}>Copyright notice</Title>
    <Paragraph>
        <ul style={{ listStylePosition: "inside" }}>
            <li>The copyright holder of Morfeusz 2 (the program) is Institute of Computer Science PAS.</li>
            <li>The authors and copyright holders of SGJP inflectional data are Zygmunt Saloni, Włodzimierz Gruszczyński, Marcin Woliński, Robert Wołosz and Danuta Skowrońska.</li>
            <li>The copyright holder of Polimorf inflectional data is Institute of Computer Science PAS.</li>
        </ul>
    </Paragraph>
    <Paragraph>
        All rights reserved.

        Redistribution and use in source and binary forms, with or without modification, are permitted provided that the following conditions are met:

        Redistributions of source code must retain the above copyright notice, this list of conditions and the following disclaimer.
        Redistributions in binary form must reproduce the above copyright notice, this list of conditions and the following disclaimer in the documentation and/or other materials provided with the distribution.
        THIS SOFTWARE IS PROVIDED BY COPYRIGHT HOLDERS “AS IS” AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL COPYRIGHT HOLDERS OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
    </Paragraph>
</Typography>)

const About = () => {
    return (
        <Collapse style={{ width: "95%" }} items={[{ key: 1, label: "Morfeusz2 Copyright Notice", children: morf }]} />
    )
}

export default About