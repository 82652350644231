import React from 'react';
import { Card, Table } from 'antd';

const columns = [
    {
        title: '',
        dataIndex: 'case_label',
        key: 'case_label',
        align: "center",
        width: "10%"
    },
    {
        title: '',
        dataIndex: 'word',
        key: 'word',
        align: "center",
        width: "10%"
    },
]

const NounFormCard = ({ number_label, cases }) => {
    // data should have 2 columns
    return (
        <Card title={number_label}>
            <Table columns={columns} dataSource={cases} pagination={false} showHeader={false} size={'small'} />
        </Card>
    )
}

export default NounFormCard
