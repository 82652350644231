export const locales = {
    "en": {
        "processButtonLabel": "Analyze",
        "conjugateButtonLabel": "Submit",
        "menuHomeItemLabel": "Home",
        "menuProcessItemLabel": "Analyze",
        "menuConjugateItemLabel": "Verbs",
        "menuNounsItemLabel": "Nouns",
        "menuAboutItemLabel": "About",
        "processTextValidation": "Please enter text",
        "processTextPlaceholder": "Add polish text here",
        "conjugateVerbValidation": "Please enter an infinitive verb",
        "conjugateVerbPlaceholder": "Select an infinitive",
        "nounPlaceholder": "Select a noun",
        "nounButtonLabel": "Submit",
        "nounValidation": "Please enter a noun",
        "homeWelcomeText": "Welcome to Process Polish!",
        "homeDescriptionText": "Process Polish is a set of tools for those studying polish language",
        "homeConjugateDescriptionText": "Polish verb conjugation. Information about polish verbs",
        "homeNounsDescriptionText": "Polish noun case endings. Information about polish nouns",
        "homeProcessDescriptionText": "Pipeline analysis of polish text",
        "startTypingText": "Start typing to find words"
    },
    "pl": {
        "processButtonLabel": "Analizuj",
        "conjugateButtonLabel": "Zatwierdź",
        "menuHomeItemLabel": "Strona Główna",
        "menuProcessItemLabel": "Analiza",
        "menuConjugateItemLabel": "Czasowniki",
        "menuNounsItemLabel": "Rzeczowniki",
        "menuAboutItemLabel": "O Stronie",
        "processTextValidation": "Proszę wpisać tekst",
        "processTextPlaceholder": "Wpisz tu polski tekst",
        "conjugateVerbValidation": "Proszę wpisać bezokolicznik",
        "conjugateVerbPlaceholder": "Wybierz bezokolicznik",
        "nounPlaceholder": "Wybierz rzeczownik",
        "nounButtonLabel": "Zatwierdź",
        "nounValidation": "Proszę wpisać rzeczownik",
        "homeWelcomeText": "Witaj na Process Polish!",
        "homeDescriptionText": "Process Polish jest zestawem narzędzi dla osób uczących się języka polskiego.",
        "homeConjugateDescriptionText": "Koniugacja czasownika polskiego. Informacje o czasownikach polskich",
        "homeNounsDescriptionText": "Polskie końcówki rzeczowników. Informacje o rzeczownikach polskich",
        "homeProcessDescriptionText": "Analiza tekstu polskiego",
        "startTypingText": "Zacznij pisać, aby znaleźć słowa"
    },
    "uk": {
        "processButtonLabel": "аналізувати",
        "conjugateButtonLabel": "Надіслати",
        "menuHomeItemLabel": "Головна сторінка",
        "menuProcessItemLabel": "аналізувати",
        "menuConjugateItemLabel": "Дієслова",
        "menuNounsItemLabel": "Іменники",
        "menuAboutItemLabel": "Про сторінку",
        "processTextValidation": "будь ласка, введіть текст",
        "processTextPlaceholder": "Введіть польський текст тут",
        "conjugateVerbValidation": "Будь ласка, введіть інфінітивне дієслово",
        "conjugateVerbPlaceholder": "Виберіть інфінітив",
        "nounPlaceholder": "Виберіть іменник",
        "nounButtonLabel": "Надіслати",
        "nounValidation": "Будь ласка, введіть іменник",
        "homeWelcomeText": "Ласкаво просимо до Process Polish!",
        "homeDescriptionText": "Process Polish це набір інструментів для людей, які вивчають польську мову.",
        "homeConjugateDescriptionText": "Польське дієвідмінювання. Інформація про польські дієслова",
        "homeNounsDescriptionText": "Польські відмінкові закінчення іменників. Інформація про польські іменники",
        "homeProcessDescriptionText": "Aналіз польського тексту",
        "startTypingText": "Почніть друкувати, щоб знайти слова"
    },
}